import { ConnectedRouter } from "connected-react-router";
import { debounce } from "lodash";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Router } from "react-router-dom";
import { ThemeProvider as SCTheme } from "styled-components";
import { ENVIRONMENTS, SOCKET_URL, currentEnv } from ".";
import Routes from "./Routes";
import ScrollToTop from "./ScrollToTop";
import ToastList from "./components/Toasts/ToastList";
import useCompanySetting from "./hooks/useCompanySetting";
import { initIntercom } from "./integrations/Intercom";
import { localLogin, setScreenSize } from "./store/app/actions";
import appConstants from "./store/app/constants";
import {
  getIntercomData,
  getToken,
  setTenantPortalSetting,
} from "./store/localStorage";
import { useFilteredTenantPortalSettings } from "./store/portalSettings";
import { history } from "./store/store";
import { handleSocketUpdates } from "./store/utils";
import theme from "./theme";
let socket;

function App() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => !!state?.app?.user?.id);
  const [setting, settingLoading] = useCompanySetting(isAuthenticated);
  const tenantPortalSettingId = useSelector(
    (state) => state.app.tenantPortalSetting
  );

  const [allSettings] = useFilteredTenantPortalSettings("");

  const user = useSelector((state) => state.app.user);
  const apartments = useSelector((state) => state.apartment.all);
  const indp = useSelector((state) => state.industrialPremises.all);
  const tenants = useSelector((state) => state.tenants.all);

  const intercomData = localStorage.getItem("intercom_data");
  const customerId = intercomData
    ? JSON.parse(intercomData ?? {})?.customer?.id
    : undefined;

  React.useEffect(() => {
    if (tenantPortalSettingId != null) return;

    if (allSettings?.[0]?.id) {
      setTenantPortalSetting(allSettings?.[0]?.id);
      dispatch({
        type: appConstants.SET_TPS,
        payload: {
          tenantPortalSetting: allSettings?.[0]?.id,
        },
      });
    }
  }, [allSettings, tenantPortalSettingId, dispatch]);

  const [hasInitializedFreshchat, setHasInitializedFreshchat] =
    React.useState(false);

  const concatString = (listOne, listTwo) => {
    let mergedList = [...listOne, ...listTwo];
    return mergedList.join(", ");
  };
  const getStrRep = (apartments, indp) => {
    let apartmentList = [];
    let indpList = [];
    if (apartments) {
      apartmentList = Object.values(apartments)?.map((value) => {
        return value.str_representation;
      });
    }
    if (indp) {
      indpList = Object.values(indp)?.map((value) => {
        return value.str_representation;
      });
    }
    return concatString(apartmentList, indpList);
  };
  const getTenantCustomerId = (tenants) => {
    const match = Object.values(tenants)?.find(
      (t) => t.id === user?.tenant?.id
    );
    return match?.customer_id;
  };

  const handleSetScreenSize = () => {
    dispatch(setScreenSize());
  };

  React.useEffect(() => {
    dispatch(localLogin());
  }, []);

  React.useEffect(() => {
    handleSetScreenSize();
    window.addEventListener("resize", debounce(handleSetScreenSize, 100));

    return () => {
      window.removeEventListener("resize", debounce(handleSetScreenSize));
    };
  }, []);

  function connectSocket() {
    const token =
      currentEnv === ENVIRONMENTS.DEV
        ? "" // needs token from alpha for now
        : getToken().token;

    if (token) {
      socket = new WebSocket(`${SOCKET_URL}?Token=${token}`);

      socket.onmessage = function (e) {
        handleSocketUpdates(e, dispatch);
      };

      socket.onclose = function (e) {
        setTimeout(function () {
          connectSocket();
        }, 1000);
      };

      socket.onerror = function (err) {};
    }
  }

  React.useEffect(() => {
    if (isAuthenticated && !socket) {
      connectSocket();
    }

    if (!isAuthenticated && socket) {
      try {
        socket.close();
      } catch (e) {
        console.log("could not close socket");
      }
    }

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [isAuthenticated]);

  React.useEffect(() => {
    if (hasInitializedFreshchat) return;

    if (setting && setting?.freshchat_token && window?.fcWidget && user) {
      window.fcWidget.init({
        token: setting?.freshchat_token,
        host: "https://wchat.eu.freshchat.com",
      });
      if (setting?.freschat_attributes?.includes("email")) {
        window.fcWidget.user.setEmail(user.email);
      }
      if (setting?.freschat_attributes?.includes("phone")) {
        window.fcWidget.user.setPhone(user.phone);
      }
      if (setting?.freschat_attributes?.includes("name")) {
        window.fcWidget.user.setFirstName(user.str_representation);
      }

      setHasInitializedFreshchat(true);
    }
  }, [setting, user]);

  React.useEffect(() => {
    const intercomData = getIntercomData();

    if (!intercomData) return;

    if (user && Object.keys(user)?.length) {
      const intercomData = getIntercomData();
      initIntercom({
        APP_ID: intercomData.token,
        user,
        intercomData,
      });
    }
  }, [setting, user]);

  React.useEffect(() => {
    try {
      if (hasInitializedFreshchat) {
        if (setting?.freschat_attributes?.includes("premises")) {
          window.fcWidget.user.setProperties({
            premises: getStrRep(apartments, indp),
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [apartments, indp]);

  React.useEffect(() => {
    try {
      if (hasInitializedFreshchat) {
        if (setting?.freschat_attributes?.includes("customer_id")) {
          window.fcWidget.user.setProperties({
            customer_id: getTenantCustomerId(tenants),
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [tenants]);

  React.useEffect(() => {
    if (customerId === 28) {
      if (!document) return;

      if (document.head?.innerHTML?.includes("kundo-knowledge-widget")) return;
      const script = document.createElement("script");
      script.defer = true;
      script.async = true;
      script.src =
        "https://nyttobostader.kb.kundo.se/js/kundo-knowledge-widget.js";
      document.head.appendChild(script);
    }
  }, [customerId]);

  return (
    <SCTheme theme={theme(setting?.color_code)}>
      <ConnectedRouter history={history}>
        <Router history={history}>
          <ScrollToTop />
          <ToastList />
          {!settingLoading && <Routes />}
        </Router>
      </ConnectedRouter>
    </SCTheme>
  );
}

export default App;
